/**
 * This script should be put in the head of index
 * to force redirection if the page is not accessed in iframe
 */

const KAIOS_HOMEPAGE = 'https://www.kaiostech.com/';

if (window.self === window.top) {
  window.location.replace(KAIOS_HOMEPAGE);
}
